import axios from "axios";
import { getUrlMs, handlerError, isStaging, joinArraysByKey } from "utils";
import { notification } from "antd";
import moment from "moment";
import env from "env";
import { getCardInfosByCompany } from "./card-service";

export const saveUser = async (id = null, user) => {
  try {
    delete user.companyExternal;
    if (user.birthday)
      user.birthday = moment(user.birthday).format("yyyy-MM-DD");

    user.cpf = user.cpf.replace(/\D/g, "");

    const req = {
      data: {
        ...user,
        password: id
          ? user.password
          : Math.random().toString(36).slice(-8) + "#"
      }
    };

    console.log(req);
    let res = null;
    if (id) {
      res = await axios.put(`/api/user/${id}`, req);
    } else {
      res = await axios.post(`/api/user`, req);
    }
    if (res.data && res.data.success) {
      id = res.data.data.id;
      // if (user.sendEmail || user.sendSms) {
      //     await savePasswordUser(id, user.sendEmail, user.sendSms);
      // } else if (user.password && user.confirmation) {
      //     await savePasswordUser(id, false, false, user.password, user.confirmation);
      // }
      notification.success({
        message: "Colaborador salvo com sucesso"
      });
    }
    return res.data;
  } catch (ex) {
    handlerError(ex);
    throw ex;
  }
};

export const saveUserBatch = async (user) => {
  try {
    if (user.birthday)
      user.birthday = moment(user.birthday).format("yyyy-MM-DD");
    const { matriz, canManageMatriz, manageMatriz, ...reqUser } = user;
    let req = { data: { ...reqUser } };
    const { data } = await axios.put(`/api/user/${user.id}/batch`, req);
    return data;
  } catch (ex) {
    handlerError(ex);
    return {
      success: false,
      errorMessage:
        ex.data && ex.data.errorMessage ? ex.data.errorMessage : null,
      infos: ex.data && ex.data.infos ? ex.data.infos : null
    };
  }
};

export const userRedeem = async (user, type, toBenefit, benefit, value) => {
  try {
    const { data } = await axios.post(`/api/solicitation/redeem`, {
      user: user,
      type: type,
      toBenefit: toBenefit,
      benefit: benefit,
      value: value
    });
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const savePasswordUser = async (
  id = null,
  sendEmail = false,
  sendSms = false,
  password = null,
  confirmation = null,
  link = false
) => {
  try {
    const req = {
      data: {
        sendEmail: sendEmail,
        sendSms: sendSms,
        link: link
      }
    };
    let ret;
    if (password && confirmation) {
      req.data.password = password;
      req.data.confirmation = confirmation;
      ret = await axios.put(`/api/user/${id}/password`, req);
    } else {
      ret = await axios.post(`/api/user/${id}/password`, req);
    }

    return ret.data;
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const getAllUsers = async ({
  type,
  filter,
  getChildrenCompanies = false,
  getCardsInfo = false
} = {}) => {
  try {
    const params =
      type || filter || getChildrenCompanies
        ? {
            type: type,
            filter: filter,
            getChildrenCompanies: getChildrenCompanies
          }
        : {};
    const { data } = await axios.get(`/api/user`, { params: params });

    if (getCardsInfo && data?.users) {
      const cardsInfo = await getCardInfosByCompany();
      data.users = joinArraysByKey(
        data.users,
        cardsInfo,
        "id",
        "userId",
        "cardsInfo"
      );
    }
    return data && data.users ? data.users : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllAdministrators = async (id) => {
  try {
    const res = await axios.get(`/api/user/${id}/administrators`);
    console.log(res);
    return res.data ? res.data.users : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const search = async (query) => {
  try {
    const params = { query: query };
    const { data } = await axios.get(`/api/user/search`, { params: params });
    return data && data.users ? data.users : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getUserBalance = async (user) => {
  try {
    const { data } = await axios.get(`/api/employee/balance?user=${user}`, {});
    return data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getUserBalanceId = async (user) => {
  try {
    const { data } = await axios.get(`/api/user/${user}/balance`, {});
    return data || null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getByIdUser = async (id) => {
  try {
    const { data } = await axios.get(`/api/user/${id}`);
    return data.data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getAccountsInfoByUseId = async (id) => {
  try {
    const { data } = await axios.get(`/api/user/${id}/accounts`);
    return data.data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getCurrentUser = async (id) => {
  try {
    const { data } = await axios.get(`/api/user/current`);
    return data.data ? data.data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getStatement = async (user, page) => {
  try {
    const { data } = await axios.get(
      page !== null
        ? `/api/employee/statement?user=${user}&page=${page}`
        : `/api/employee/statement?user=${user}&complete=true`,
      {}
    );
    return data.transactions ? data.transactions : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getTransaction = async (transaction, type, user) => {
  try {
    const { data } = await axios.get(
      `/api/employee/transaction?transaction=${transaction}&user=${user}` +
        (type ? "&type=" + type : ""),
      {}
    );
    return data.transaction ? data.transaction : {};
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const getStatementResume = async (company, account, user, page, url) => {
  try {
    const { data } = await axios.post(url, {
      page: page,
      user: user,
      company: company,
      account: account
    });
    return data.transactions ? data.transactions : [];
  } catch (ex) {
    console.log(ex);
    handlerError(ex);
    return [];
  }
};

export const getAllOccupation = async () => {
  try {
    const { data } = await axios.get(`/api/user/occupations`);
    return data && data.data ? data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getUserKycStatus = async (user) => {
  try {
    const { data } = await axios.get(`/api/user/${user}/kycStatus`);
    return data ? data.data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getChargesUser = async (user) => {
  try {
    const { data } = await axios.get(`/api/user/${user}/solicitations`);
    return data && data.entries ? data.entries : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getNewUserCompany = async (user, newCompany, type) => {
  try {
    const { data } = await axios.get(
      `/api/user/${user}/newCompany?company=${newCompany}&type=${type}`
    );
    return data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return ex.data ? ex.data : null;
  }
};

export const saveNewCompanyUser = async (user, body) => {
  try {
    const { data } = await axios.put(`/api/user/${user}/newCompany`, body);
    notification.success({
      message: "Colaborador salvo com sucesso!"
    });
    return data;
  } catch (ex) {
    handlerError(ex);
    return { swapCompany: false, pendingLine: ex.data.pendingLine };
  }
};

export const saveCardManager = async (company, managers = []) => {
  try {
    await axios.post(`/api/user/card/manager`, {
      company: company,
      managers: managers.map((x) => {
        return {
          manager: x.id,
          managerBranch: x.cardManagerBranch
        };
      })
    });
    notification.success({
      message: "Gerentes atualizados com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};

export const saveRegisterManager = async (company, managers = []) => {
  try {
    await axios.post(`/api/user/register/manager`, {
      company: company,
      managers: managers.map((x) => {
        return {
          manager: x.id,
          managerBranch: x.registerBranchManager
        };
      })
    });
    notification.success({
      message: "Gerentes atualizados com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};

export const getCompaniesUsers = async (user) => {
  try {
    const { data } = await axios.get(`/api/user/${user}/companies`);
    return data && data.history ? data.history : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveRefresh = async (id) => {
  try {
    await axios.post(`/api/user/${id}/refresh`);
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const saveUserManagerMatriz = async (id, enabled = false) => {
  try {
    const req = {
      enabled
    };
    await axios.put(`/api/user/${id}/manager`, req);
    notification.success({ message: "Salvo com sucesso" });
  } catch (ex) {
    handlerError(ex);
    throw ex;
  }
};

export const getAllEmployeesAvailableDriver = async (vehicle) => {
  try {
    const res = await axios({
      method: "get",
      url: `/api-core/employee/available/driver`
    });

    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};
