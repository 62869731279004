import axios from "axios";
import { handlerError } from "utils";
import { notification } from "antd";

export const getByIdCard = async (user) => {
  try {
    const { data } = await axios.get(`/api/card/${user}`);
    const card = data.card
      ? Array.isArray(data.card)
        ? [...data.card]
        : [data.card]
      : [];
    return card;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const createOrder = async (user) => {
  try {
    const { data } = await axios.post(`/api/card/solicitation`, {
      employee: user
    });
    return data ? data : {};
  } catch (ex) {
    let error = handlerError(ex);
    return { success: false, ...error };
  }
};

export const createCompanyCard = async (company, body) => {
  try {
    const { data } = await axios.post(`/api/company/${company}/card`, body);
    return data ? data.id : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
export const getCardInfosByCompany = async () => {
  try {
    const { data } = await axios.get("/api/card/company/info");
    return data?.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const loadHistoryCards = async (user) => {
  try {
    const { data } = await axios.get(`/api/card/${user}/history`);
    return data.history ? data.history : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const loadAllHistoryCards = async (company) => {
  try {
    const { data } = await axios.get(`/api/card/history`, {
      params: { company: company }
    });
    return data.history ? data.history : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllCardSolicitationByCompany = async (company) => {
  try {
    let param = {};
    if (company) {
      param = { params: { company: company } };
    }
    const { data } = await axios.get(`/api/card/solicitation`, param);
    return data ? data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const blockCard = async (userId, card) => {
  try {
    await axios.post(`/api/card/status/${userId}`, {
      status: "blocked",
      card: card.idCard
    });
    notification.success({
      message: "Cartão bloqueado com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const cancelCard = async (card) => {
  try {
    await axios.put(`/api/card/solicitation/${card}/cancel`, {});
    notification.success({
      message: "Cartão cancelado com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const redeliveryCard = async (data) => {
  try {
    await axios.post(`/api/card/resend`, { ...data });
    notification.success({
      message: "Pedido de reentrega feito com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const unblockCard = async (id) => {
  try {
    await axios.post(`/api/card/${id}/status`, { status: "active" });
    notification.success({
      message: "Cartão desbloqueado com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const loadAllSolicitationsCard = async () => {
  try {
    const { data } = await axios.get(`/api/card/solicitation/user`);
    return data.data ? data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const updateSolicitationsCard = async (id, status) => {
  try {
    await axios.put(`/api/card/solicitation/user/${id}`, { status: status });
    notification.success({
      message:
        status == "s"
          ? "Envio criado com sucesso!"
          : "Cartão entregue com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
